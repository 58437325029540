import React from "react";
import Layout from "../../components/Layout";
import { BsEye } from "react-icons/bs";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { CustomRenderer } from "@/utils/CustomRenderer";

const WhoAreWe = ({ data, pageContext }: any) => {
  const { menus } = pageContext;
  const { content } = data?.cms.page;
  return (
    <Layout title="Who we are" pageContext={menus}>
      <div className="container">
        <CustomRenderer document={content.document} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query WhoAreWe($id: ID) {
    cms {
      page(where: { id: $id }) {
        title
        id
        content {
          document(hydrateRelationships: true)
        }
      }
    }
  }
`;

export default WhoAreWe;
